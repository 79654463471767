// sass-lint:disable no-vendor-prefixes
html {
    box-sizing: border-box;
    text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
    overflow-x: hidden;
}

// sass-lint:enable no-vendor-prefixes

* {
    &::before,
    &::after {
        box-sizing: inherit;
        outline: none !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active {
        outline: none !important;
        box-shadow: none !important;
    }
}

body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    overflow-x: hidden;
}

.site {
    overflow: hidden;
}

.container,
.container-fluid {
    @include media-breakpoint-only(xs) {
        padding-left: $grid-gutter-width * 1.5 !important;
        padding-right: $grid-gutter-width * 1.5 !important;
    }
}

// hides content until vue is loaded (resolves flashy, slotted content)
[v-cloak] {
    display: none;
}
