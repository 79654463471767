@each $color, $value in $bg-colors {
    @include bg-variant('.bg-#{$color}', $value);
}

@mixin linear-gradient($direction, $color-stops...) {
    background: nth(nth($color-stops, 1), 1);
    background: linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}

// .bg-dark--halftop
// .bg-dark--halfbottom
// .bg-medium--halftop
// .bg-medium--halfbottom
// .bg-light--halftop
// .bg-light--halfbottom
@each $color, $value in $bg-halfcolors {
    .bg-#{$color} {
        @include linear-gradient(nth($value, 2), nth($value, 1) 50%, transparent 50%);

        @include media-breakpoint-down(sm) {
            [class*='col-'] {
                margin-bottom: 0;
            }
        }
    }
}
