/* stylelint-disable scss/dollar-variable-empty-line-before */
$font-size-base: 1rem; // 16px
$line-height-base: 1.2;

// breakpoints
$grid-breakpoints: (
    xs: 0,
    s: 360px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);

// grid
$grid-columns: 12;
$grid-gutter-width: 20px;
$grid-gutter-width-base: $grid-gutter-width;

$grid-gutter-widths: (
    xs: $grid-gutter-width-base,
    s: $grid-gutter-width-base,
    sm: $grid-gutter-width-base,
    md: $grid-gutter-width-base,
    lg: $grid-gutter-width-base,
    xl: $grid-gutter-width-base,
);

// when combined with bootstrap's media-breakpoint-up mixin,
// the s breakpoint uses its exact value, but the others
// seem to add 40px (2 * gutter?), e.g. sm starts at 576px, md at 768px etc
$container-max-widths: (
    s: 360px,
    sm: 536px,
    md: 728px,
    lg: 952px,
    xl: 990px,
);

$border-default: 1px solid $grey-400;
