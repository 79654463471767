.has-divider-as-background {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        height: 1px;
        border-top: 1px solid $grey-400;
    }
}
