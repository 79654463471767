.print-only {
    display: none;
}

@media print {
    body > * {
        display: none;
    }

    // edge exclusion
    @supports (-ms-ime-align: auto) {
        body {
            display: block;
            overflow: visible !important;
        }
    }

    // ie 10 / 11 exclusion
    @media all and (-ms-high-contrast: none) {
        body {
            display: block;
            overflow: visible !important;
        }

        *::-ms-backdrop,
        body {
            display: block;
            overflow: visible !important;
        }
    }

    header,
    footer {
        display: none;
    }

    .print-only {
        margin: 2.5rem;
        display: block !important;
    }

    // otc print summary
    .summary-item {
        padding-left: 1rem;
        position: relative;

        &::before {
            content: ' ';
            position: absolute;
            top: 0.6rem;
            left: 0;
            width: 0.25rem;
            height: 0.25rem;
            background: #000;
            border-radius: 50%;
        }
    }

    .summary-category {
        clear: both;
        overflow: hidden;
        padding: 2rem 0 1.5rem;
    }

    .summary-featuregroup-item {
        list-style: none;
    }

    .summary-item-title {
        display: inline-block;
        width: 80%;
        line-height: 1.5;
    }
}
