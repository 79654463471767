@use 'sass:math';

@mixin text-custom-variant($parent, $color) {
    #{$parent} {
        color: $color !important;
    }
    a#{$parent} {
        color: darken($color, 10%) !important;
    }
}

/// Create styles from theme colors and breakpoints
/// so we can define .text-md-light and have text become 'light' from breakpoint md and up
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin text-breakpoint-variant($parent, $breakpoint, $color) {
    #{$parent} {
        @include media-breakpoint-up($breakpoint) {
            color: $color !important;
        }
    }
    a#{$parent} {
        @include media-breakpoint-up($breakpoint) {
            color: darken($color, 10%) !important;
        }
    }
}

/// Alignes elements vertically and horizontally.
/// @param {string} $position [relative] Optional relative (default) or absolute
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin align-center($position: relative) {
    position: $position;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/// Horizontally alignes elements.
/// @param {string} $position [relative] Optional relative (default) or absolute
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin align-horizontal($position: relative) {
    position: $position;
    left: 50%;
    transform: translateX(-50%);
}

/// Vertically alignes elements.
/// @param {string} $position [relative] Optional relative (default) or absolute
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin align-vertical($position: relative) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
}

/// Removes top/bottom margin of elements in a container.
/// @param {string} $selector ['> *'] Optional element selector to remove margins from
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin content-margins($selector: '> *') {
    @if $selector {
        #{unquote($selector)} {
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    } @else {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

///	Creates a 'CSS only' triangle.
/// @author Rocco Janse, <rocco.janse@valtech.nl>
/// @param {string} $color Color hexvalue, string
/// @param {string} $direction Up, down, left or right
/// @param {string} $size [6px] Default: 6px
/// @param {string} $position [absolute] Default: absolute
/// @param {boolean} $round [false] True or false (default)
///	@example
///	.element {
///		&:before {
///			@include css-triangle(blue, down);
///		}
///	}
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
    content: '';
    display: block;
    position: $position;
    width: 0;
    height: 0;

    @if $round {
        border-radius: 3px;
    }

    @if $direction == down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round(math.div($size, 2.5));
    } @else if $direction == up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round(math.div($size, 2.5));
    } @else if $direction == right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    } @else {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    }
}

/// Anti alias fonts.
/// @param {string} $value [antialiased] None, antialiased (default), subpixel-antialiased
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin font-smoothing($value: antialiased) {
    @if $value == antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

/// Fills the width and height of a relative parent using position: absolute
@mixin fill-parent-absolute() {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/// Hide from both screenreaders and browsers.
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin hidden {
    display: none;
    visibility: hidden;
}

/// Style `<input/>` placeholder.
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin input-placeholder {
    ::placeholder {
        @content;
    }
}

/// Hide visually and from screenreaders, but maintain layout.
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin invisible {
    visibility: hidden;
}

/// Unitless line height based font-size.
/// @param {string} $height Height in pixels
/// @param {string} $font-size Font size in pixels
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin line-height($height, $font-size) {
    line-height: math.div(strip-units($height), strip-units($font-size));
}

/// On touch mixin for quick setting `:hover`, `:focus` `:active`.
/// @param {boolean} $hover [true] Set `:hover` and `.hover`
/// @param {boolean} $focus [true] Set `:focus` and `.focus`
/// @param {boolean} $active [true] Set `:active` and `.active`
/// @author Peter Bust, <peter.bust@valtech.nl>
@mixin on-touch($hover: true, $focus: true, $active: true) {
    @if $hover {
        &.hover,
        &:hover {
            @content;
        }
    }
    @if $focus {
        &.focus,
        &:focus {
            @content;
        }
    }
    @if $active {
        &.active,
        &:active {
            @content;
        }
    }
}

/// Cross browser opacity.
/// @param {float} $opacity Opacity float (ie. 0.5)
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin opacity($opacity) {
    $opacity-ie: $opacity * 100;

    filter: alpha(opacity=$opacity-ie); // IE8
    opacity: $opacity;
}

/// Set elements aspect ratio.
/// @param {string} $element-w [100%] Width of element
/// @param {string} $ratio-w [1] Aspect ratio width
/// @param {string} $ratio-h [1] Aspect ratio height
/// @param {boolean} $restrain [true] If true, element is kept in ratio, overflowing content is hidden. If false, height is relative to content with a minimum ratio height.
/// @example scss - Retrain ratio:
/// 	.example {
///			@include ratio-box(500px, 16, 9);
///			width: 500px;
/// 	}
/// @example scss - Height is relative to content with a minimum ratio height:
/// 	.example {
///			@include ratio-box(500px, 16, 9, false);
///			width: 500px;
/// 	}
/// @author Peter Bust, <peter.bust@valtech.nl>
@mixin ratio-box($element-w: 100%, $ratio-w: 1, $ratio-h: 1, $restrain: true) {
    @if $restrain {
        // height: 0 !important;
        // padding-bottom: $element-w / $ratio-w * $ratio-h;
        // overflow: hidden !important;

        height: 0;
        overflow: hidden;
        padding-top: math.div($ratio-h, $ratio-w) * $element-w;
        position: relative;
    } @else {
        height: auto;

        &::before {
            // content: "";
            // float: left;
            // padding-bottom: 100% / $ratio-w * $ratio-h;

            content: '';
            width: 1px;
            margin-left: -1px;
            float: left;
            height: 0;
            padding-top: math.div($ratio-h, $ratio-w) * $element-w;
        }

        &::after {
            content: '';
            display: table;
            clear: both;
        }
    }
}

/// ratio-box @extend
%ratio-box {
    @include ratio-box;
}

/// Hide only visually, but have it available for screenreaders.
/// @param {boolean} $focusable [false] Should the element be focusable
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin screenreader($focusable: false) {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    @if $focusable {
        @include screenreader-focusable;
    }
}

/// Allow the element to be focusable when navigated to via the keyboard.
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin screenreader-focusable {
    &:active,
    &:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

/// Resets component after hidden for screenreaders.
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin screenreader-reset {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/// Simple vertical gradient.
/// @param {string} $top [$brand-secondary-light] Top color (hex)
/// @param {string} $bottom [$brand-secondary] Bottom color (hex)
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin simple-gradient($top: $brand-secondary-light, $bottom: $brand-secondary) {
    background: rgba($bottom, 1);
    background: linear-gradient(to bottom, rgba($top, 1) 0, rgba($bottom, 1) 100%);
    filter: progid:dximagetransform.microsoft.gradient(startcolorstr=$top, endcolorstr=$bottom, gradienttype=0);
}

/// Disable browser selection of elements. Usefull for menu's, navigations etc.
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin unselectable {
    -webkit-touch-callout: none;
    user-select: none;
}

/// Set element visible.
/// @param {string} $state ['block'] Display state.
/// @author Rocco Janse, <rocco.janse@valtech.nl>
@mixin visible($state: 'block') {
    display: unquote($state);
    visibility: visible;
}

/// Transitioning.
/// @param {string} $args | property duration timing-function delay|initial|inherit;
/// @author Peter van Gennip, <peter.van.gennip@valtech.nl>
@mixin transition($args...) {
    transition: $args;
}
