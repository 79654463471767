/* stylelint-disable scss/dollar-variable-empty-line-before */
$white: #fff !default;
$black: #000 !default;
$off-black: #161616 !default;

$grey-50: #f9f9f9 !default;
$grey-100: #f1f1f1 !default;
$grey-200: #dbdedf !default;
$grey-300: #ced3d6 !default;
$grey-400: #b9c1c6 !default;
$grey-500: #868e96 !default;
$grey-600: #656b6f !default;
$grey-700: #4d545a !default;
$grey-800: #353c45 !default;
$grey-900: #21252b !default;

$brand-primary-dark: #1b4770 !default;
$brand-primary: #00529c !default;
$brand-primary-light: #266cab !default;
$brand-primary-lighter: #80a9ce !default;
$brand-secondary: #fc5f01 !default;
$brand-btn-blue-gradient: linear-gradient(180deg, #266cab, #00529c) !default;

$brand-truck-configurator-blue: #0052a5 !default;
$brand-truck-configurator-grey: #444 !default;
$brand-truck-configurator-success-green: #40ca50 !default;

$validation-warning-light-green: #b3e7b7;
$validation-warning-light-red: #f2b3b9;
$validation-warning-green: #00ae10;
$validation-warning-red: #d50017;

$red: #dc3545 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$cyan: #17a2b8 !default;

$theme-colors: (
    primary-dark: $brand-primary-dark,
    primary: $brand-primary,
    primary-light: $brand-primary-light,
    primary-lighter: $brand-primary-lighter,
    secondary: $brand-secondary,
    success: $green,
    info: $cyan,
    warning: $yellow,
    danger: $red,
    grey-300: $grey-300,
    grey-500: $grey-500,
    grey-800: $grey-800,
);

$bg-colors: (
    light: $white,
    medium: $grey-100,
    dark: $grey-800,
    blue: $brand-primary,
    black: $off-black,
);

$bg-halfcolors: (
    light--halftop: (
        $white,
        180deg,
    ),
    light--halfbottom: (
        $white,
        0deg,
    ),
    medium--halftop: (
        $grey-100,
        180deg,
    ),
    medium--halfbottom: (
        $grey-100,
        0deg,
    ),
    dark--halftop: (
        $grey-800,
        180deg,
    ),
    dark--halfbottom: (
        $grey-800,
        0deg,
    ),
);

$heading-colors: (
    light: $white,
    medium: $grey-100,
    dark: $grey-800,
);

$text-colors: (
    light: $white,
    medium: $grey-100,
    dark: $grey-600,
);
