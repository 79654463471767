// sitecore pagemode edit

.pagemode-edit,
.pagemode-preview,
.pagemode-debug {
    .bg-change {
        position: absolute;
        top: 30px;
        left: 30px;
        width: 50px;
        max-height: 50px;
        border: 2px solid $white;
        cursor: pointer;
        overflow: hidden;
        box-shadow: 0 0 20px 5px rgba($black, 0.75);
        z-index: 100;

        img {
            margin: 0;
            padding: 0;
            width: 100%;
        }

        &:hover {
            filter: sepia(0.7);
        }
    }
}
