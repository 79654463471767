.align {
    &--center {
        @include align-horizontal(relative);
    }

    &--right {
        position: relative;
        left: 100%;
        transform: translateX(-100%);
    }
}
